import React from 'react'
import { ToastContainer } from 'react-toastify'
import SubscriptionBar from './components/SubscriptionBar';
import { getPayingCustomer } from './redux/UserSlice';
import { useSelector } from 'react-redux';
import Header from './components/Header';
import { matchPath, useLocation } from 'react-router-dom';
import { useSubscription } from './utils/SubscriptionContext';
import TrialEndModal from './components/TrialEndModal';

function Layout({ children }) {
  const location = useLocation();
  const payingCustomer = useSelector(getPayingCustomer);
  const { daysLeft } = useSubscription();
  const userId = localStorage.getItem('user_id') || "";
  const headerPaths = userId ? ['/', '/add-report', '/scribe', '/edit-report', '/meeting', '/consult', '/settings'] : ['/add-report', '/scribe', '/edit-report', '/meeting', '/consult', '/settings'];
  const showHeader = headerPaths.includes(location.pathname) || matchPath('/scribe/:id', location.pathname);
  const trialEnded = !(daysLeft === null || payingCustomer === null || payingCustomer) && (daysLeft <= 0)

  return (
    <div>
      {trialEnded && <TrialEndModal />}
      <SubscriptionBar />
      <div className={` ${trialEnded ? 'blur pointer-events-none' : ''}`}>
        <div id='reportInfoBody' className={`App w-full flex flex-col items-center justify-center font-SuisseIntl`}>
          <ToastContainer />
          {showHeader && <Header payingCustomer={payingCustomer} />}
          <>
            {children}
          </>
        </div>
      </div>
    </div>
  )
}

export default Layout
