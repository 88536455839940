import React, { useState } from "react";
import {
  Card,
  CardContent,
} from "@/components/shadcn-ui/card";
import { Dialog, DialogContent, DialogTrigger } from "@/components/shadcn-ui/dialog";
import { Button } from "@/components/shadcn-ui/button";
import { Label } from "@/components/shadcn-ui/label";
import { Input } from "@/components/shadcn-ui/input";
import { Textarea } from "@/components/shadcn-ui/textarea";
import deleteIcon from "@/images/SVG/deleteIcon.svg";
import AddSubstitute from "@/components/AddSubstitute";
import Loader from "./Loader";
import { deleteSubstitute } from "@/utils/HelperFunctions";
import { removeSubstitute } from "@/redux/ProfileSlice";
import { useDispatch } from "react-redux";

export const AddButton = ({ 
  label, 
  type, 
  isOpen, 
  setIsOpen, 
  className = "",
  buttonClassName = "" 
}) => (
  <Dialog className={className} open={isOpen} onOpenChange={setIsOpen}>
    <DialogTrigger className={className}>
      <Button className={`w-full ${buttonClassName}`}>
        {label}
      </Button>
    </DialogTrigger>
    <DialogContent className="rounded-3xl px-8 py-4">
      <AddSubstitute type={type} setIsOpen={setIsOpen} title={label}/>
    </DialogContent>
  </Dialog>
);

export const FindReplaceDesktop = ({ 
  items, 
  mode = "vocabulary"
}) => {
  if (mode === "vocabulary") {
    return (
      <CardContent className="md:block hidden p-0">
        <Card className="rounded-2xl shadow-none border-none">
          {items.map((item, index) => (
            <CardContent
              key={item.id}
              className={`${
                index === items.length - 1 ? "p-4" : "p-4 pb-0"
              }`}
            >
              <div className="flex justify-between gap-4">
                <div className="flex-1 flex flex-col gap-4">
                  <div className="flex space-x-4">
                    <div className="flex-1 flex flex-col space-y-1.5">
                      <Label className="font-semibold text-gray-700">Find</Label>
                      <Input 
                        type="text" 
                        value={item.find} 
                        readOnly 
                        className="font-semibold text-sm rounded-lg opacity-50 border-slate-200 bg-white" 
                      />
                    </div>
                    <div className="flex-1 flex flex-col space-y-1.5">
                      <Label className="font-semibold text-gray-700">Replace</Label>
                      <Input 
                        type="text" 
                        value={item.replace} 
                        readOnly 
                        className="font-semibold text-sm rounded-lg opacity-50 border-slate-200 bg-white" 
                      />
                    </div>
                  </div>
                </div>

                <div className="flex-none mt-[18px]">
                  <DeleteButton id={item.id} />
                </div>
              </div>
            </CardContent>
          ))}
          <EmptyState items={items} mode={mode} view="desktop" />
        </Card>
      </CardContent>
    );
  }

  return (
    <CardContent className="md:block hidden p-0">
      <Card className="rounded-2xl shadow-none border-none">
        {items.map((item, index) => (
          <CardContent
            key={item.id}
            className={`${
              index === items.length - 1 ? "p-4" : "p-4 pb-0"
            }`}
          >
            <div className="flex justify-between gap-4">
              <div className="flex-1 flex flex-col gap-4">
                <div className="flex flex-col space-y-1.5">
                  <Label className="font-semibold text-gray-700">Find</Label>
                  <Input 
                    type="text" 
                    value={item.find} 
                    readOnly 
                    className="font-semibold text-sm rounded-lg opacity-50 border-slate-200 bg-white" 
                  />
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label className="font-semibold text-gray-700">Replace</Label>
                  <Textarea 
                    value={item.replace} 
                    readOnly 
                    className="h-[170px] max-w-full overflow-y-auto font-semibold text-sm rounded-lg opacity-50 border-slate-200 bg-white" 
                  />
                </div>
              </div>

              <div className="flex-none mt-[18px]">
                <DeleteButton id={item.id}/>
              </div>
            </div>
          </CardContent>
        ))}
        <EmptyState items={items} mode={mode} view="desktop" />
      </Card>
    </CardContent>
  );
};

export const FindReplaceMobile = ({ 
  items, 
  mode = "vocabulary", 
  isDialogOpen, 
  setIsDialogOpen 
}) => {
  return (
    <CardContent className="md:hidden p-0 border-none">
      <Card className="rounded-2xl shadow-none border-none">
        <CardContent className="py-4 px-0 w-full">
          <div className="w-full">
            <AddButton
              label={mode === "vocabulary" ? "Add Vocabulary" : "Add Text Module"}
              type={mode === "vocabulary" ? "vocabulary" : "text_module"}
              isOpen={isDialogOpen}
              setIsOpen={setIsDialogOpen}
              className="flex md:hidden w-full px-4" 
              buttonClassName="w-full mb-4" 
            />
          </div>
          <div className="border-b border-slate-200 w-full" />
          <div className="flex flex-col gap-4 mt-4">
            {items.map((item) => (
              <div key={item.id}>
                <div className="flex items-start justify-between gap-4 px-4">
                  <FindReplaceInputs item={item} mode={mode} />
                  <DeleteButton id={item.id} className="ml-4 mt-2" />
                </div>
              </div>
            ))}
            <EmptyState items={items} mode={mode} view="mobile" />
          </div>
        </CardContent>
      </Card>
    </CardContent>
  );
};

const FindReplaceInputs = ({ item, mode }) => {
  if (mode === "vocabulary") {
    return (
      <div className="w-full">
        <div className="flex gap-1 items-center h-10 border px-3 py-1 border-b-0 rounded-t-lg">
          <Label className="text-gray-700 font-semibold text-sm">Find:</Label>
          <Input 
            type="text" 
            value={item.find} 
            readOnly 
            className="w-full font-semibold opacity-50 bg-opacity-40 overflow-y-scroll h-full mt-[6px]" 
          />
        </div>
        <div className="flex gap-1 items-center h-10 border px-3 py-1 rounded-b-lg">
          <Label className="text-gray-700 font-semibold text-sm">Replace:</Label>
          <Input 
            type="text" 
            value={item.replace} 
            readOnly 
            className="w-full font-semibold opacity-50 bg-opacity-40 overflow-y-scroll h-full mt-[6px]" 
          />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="flex items-center rounded-t-lg gap-1 h-10 w-full border border-b-0 border-slate-200 bg-white px-3 py-2 text-sm">
        <Label className="font-semibold text-gray-700">Find:</Label>
        <Input 
          type="text" 
          value={item.find} 
          readOnly 
          className="font-semibold w-full opacity-50 overflow-y-scroll" 
        />
      </div>
      <div className="flex flex-col gap-1 rounded-b-lg h-[170px] w-full border border-slate-200 bg-white px-3 py-2">
        <Label className="font-semibold text-gray-700">Replace:</Label>
        <Textarea 
          value={item.replace} 
          readOnly 
          className="h-full overflow-y-auto font-semibold opacity-50" 
        />
      </div>
    </div>
  );
};

const DeleteButton = (id) => {
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);

  const handleDelete = async () => {
    setDeleting(true);
    const response = await deleteSubstitute(id);
    if (response) {
      dispatch(removeSubstitute(response.id));
    }
    setDeleting(false);
  };

  return (
    <Button
      variant="outline"
      size="icon"
      onClick={handleDelete}
      disabled={deleting}
    >
      {
        deleting ? 
          <Loader variant='small'/> 
        : 
        <img
          src={deleteIcon}
          alt="delete"
          className="max-w-none w-4 h-4"
        />
      }
      
    </Button>
  );
}

const EmptyState = ({ items, mode, view }) => {
  if (items.length > 0) return null;

  const message = `No ${mode === "vocabulary" ? "Vocabulary" : "Text Module"} found. Please Add one`;
  const className = `p-4 text-center ${view === "mobile" ? "text-sm" : "text-xl"} font-medium text-[#71717A] flex items-center ${view === "mobile" ? "h-96" : "h-48"} justify-center`;

  return <p className={className}>{message}</p>;
};