export const pageSize = 50

export const upperCase = (text) => {
  if (!text) return ""; 
  let str = text.split(/[\s_-]+/);
  if (str[str.length-1] === 'template') str.pop();
  return str.map(s => s.toUpperCase() === 'AI' ? 'AI' : s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()).join(" ")
};

export const allModelsLanguageList = [
    { name: "English", value: "en" },
    { name: "German (Switzerland)", value: "german (switzerland)" },
    { name: "Finnish", value: "fi" },
    { name: "German", value: "de" },
    { name: "Spanish", value: "es" },
    { name: "French", value: "fr" },
    { name: "Italian", value: "it" },
    { name: "Portuguese", value: "pt" },
    { name: "Dutch", value: "nl" },
    { name: "Hindi", value: "hi" },
    { name: "Japanese", value: "ja" },
    { name: "Korean", value: "ko" },
    { name: "Polish", value: "pl" },
    { name: "Russian", value: "ru" },
    { name: "Turkish", value: "tr" },
    { name: "Ukrainian", value: "uk" },
    { name: "Vietnamese", value: "vi" },
];

export const languageList = [
    {
      "name": "Bulgarian",
      "value": "bg"
    },
    {
      "name": "Catalan",
      "value": "ca"
    },
    {
      "name": "Czech",
      "value": "cs"
    },
    {
      "name": "Danish",
      "value": "da, da-DK"
    },
    {
      "name": "Dutch",
      "value": "nl"
    },
    {
      "name": "English",
      "value": "en"
    },
    {
      "name": "Estonian",
      "value": "et"
    },
    {
      "name": "Finnish",
      "value": "fi"
    },
    {
      "name": "French",
      "value": "fr"
    },
    {
      "name": "German",
      "value": "de"
    },
    {
      "name": "German (Switzerland)",
      "value": "german (switzerland)"
    },
    {
      "name": "Greek",
      "value": "el"
    },
    {
      "name": "Hindi",
      "value": "hi"
    },
    {
      "name": "Hungarian",
      "value": "hu"
    },
    {
      "name": "Indonesian",
      "value": "id"
    },
    {
      "name": "Italian",
      "value": "it"
    },
    {
      "name": "Japanese",
      "value": "ja"
    },
    {
      "name": "Korean",
      "value": "ko"
    },
    {
      "name": "Latvian",
      "value": "lv"
    },
    {
      "name": "Lithuanian",
      "value": "lt"
    },
    {
      "name": "Malay",
      "value": "ms"
    },
    {
      "name": "Norwegian",
      "value": "no"
    },
    {
      "name": "Polish",
      "value": "pl"
    },
    {
      "name": "Portuguese",
      "value": "pt"
    },
    {
      "name": "Romanian",
      "value": "ro"
    },
    {
      "name": "Russian",
      "value": "ru"
    },
    {
      "name": "Slovak",
      "value": "sk"
    },
    {
      "name": "Spanish",
      "value": "es"
    },
    {
      "name": "Swedish",
      "value": "sv"
    },
    {
      "name": "Thai",
      "value": "th"
    },
    {
      "name": "Turkish",
      "value": "tr"
    },
    {
      "name": "Ukrainian",
      "value": "uk"
    },
    {
      "name": "Vietnamese",
      "value": "vi"
    }
]
