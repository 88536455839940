import React from 'react'
import { pageSize } from '../utils/constants';
import { ChevronLeft, ChevronRight } from 'lucide-react';

function Pagination({  page, setPage, total, totalPages }) {
  const first = page <= 1
  const final = page >= Math.ceil(total / pageSize)

  const handleNext = () => {
    if (!final) {
      setPage(page + 1);
    }
  };

  const handlePrev = () => {
    if (!first) {
      setPage(page - 1);
    }
  };

  const renderPageNumbers = () => {
    const totalPages = Math.ceil(total / pageSize);
    const currentPageGroup = Math.ceil(page / 5);
    const startPage = (currentPageGroup - 1) * 5 + 1;
    const endPage = Math.min(currentPageGroup * 5, totalPages);
    return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map((item) => (
      <p
        key={item}
        onClick={() => setPage(item)}
        className={`flex items-center cursor-pointer font-SuisseIntlLight font-semibold text-sm justify-center p-2 text-black  ${page === item ? 'border rounded-lg' : ''
          } `}
      >
        <span className='pagination-number h-4 w-4 text-center	'>{item}</span>
      </p>
    ));
  };
  if (totalPages > 1) {
  return (
    <div className="flex  text-[#525866] justify-center md:justify-end items-center gap-2">
          <p
            onClick={handlePrev}
            className={`flex items-center ${ first ? "cursor-not-allowed" : "cursor-pointer" } gap-1 w-fit justify-center p-2 text-sm text-black font-SuisseIntlLight font-semibold`}
          >
            {/* <span className='pagination-arrow h-4 w-4 text-center	'>←</span> */}
            <ChevronLeft className='pagination-arrow w-4'/>
            <span>Previous</span>
          </p>
          <div className="flex gap-2">{renderPageNumbers()}</div>
          <p
            onClick={handleNext}
            className={`flex items-center ${ final ? "cursor-not-allowed" : "cursor-pointer" } gap-1 w-fit justify-center p-2 text-sm text-black font-SuisseIntlLight font-semibold`}
          >
            <span>Next</span>
            <ChevronRight className='pagination-arrow w-4' />
            {/* <span className='pagination-arrow h-4 w-4 text-center	'>→</span> */}
          </p>


    </div>
  )
  }
}

export default Pagination
