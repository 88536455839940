import { createSlice } from '@reduxjs/toolkit'
import { FetchBackendReports, FetchReport, FetchReportTemplates, UpdateReportStatus } from './ReportsApis'
import { pageSize } from '../utils/constants';

const initialState = {
  loading: false,
  reports: [],
  reportTemplates: [],
  fileUploading: false,
  isDialogOpen: false,
  allReports: {
    reports: [],
    totalPages: 0,
    totalCount: 0
  },
  processingReports: {
    reports: [],
    totalPages: 0,
    totalCount: 0
  },
  inProgressReports: {
    reports: [],
    totalPages: 0,
    totalCount: 0
  },
  completedReports: {
    reports: [],
    totalPages: 0,
    totalCount: 0
  },
  generatedReports: {
    reports: [],
    totalPages: 0,
    totalCount: 0
  },
  urgentReports: {
    reports: [],
    totalPages: 0,
    totalCount: 0
  },
  selectedReport: {},
  totalReports: 0,
  totalPages: 0,
  latestPayload: null,
  error: '',
  updating: false
}

const getReportState = (state, tabType) => {
  const reportStateMap = {
    'all': state.allReports,
    'processing': state.processingReports,
    'generated': state.generatedReports,
    'in-progress': state.inProgressReports,
    'completed': state.completedReports,
    'urgent': state.urgentReports
  };
  return reportStateMap[tabType];
}

const findAndReplaceReport = (reports, report) => {
  for (let index = 0; index < reports.length; index++) {
    if (reports[index].id === report.id) {
      reports[index] = report
      break;
    }
  }
}

export const reportSlice = createSlice({
  name: 'reportsSlice',
  initialState,
  reducers: {
    setReportUpdate: (state, action) => {
      state.updating = action.payload
    },
    setFileUploading: (state, action) => {
      state.fileUploading = action.payload
    },
    setIsDialogOpen: (state, action) => {
      state.isDialogOpen = action.payload
    },
    setSelectedReport: (state,action) => {
      state.selectedReport = {...action.payload}
    },
    setLatestPayload: (state, action) => {
      state.latestPayload = action.payload
    },
    UpdateReport: (state, action) => {
      const updatedReport = action.payload;
      const index = state.allReports.reports.findIndex(report => report.id === updatedReport.id);
      if (index !== -1) {
        state.allReports.reports[index] = updatedReport;
        state.processingReports.reports = state.processingReports.reports.filter(report => report.id !== updatedReport.id);
      }
      else {
        state.allReports.reports.unshift(updatedReport);
        const reportState = getReportState(state, updatedReport.status);
        reportState.reports.unshift(updatedReport);
        reportState.totalCount += 1;
      }
    },
    removeReport: (state, action) => {
      const updatedReport = action.payload;
      state.processingReports.reports = state.processingReports.reports.filter(report => report.id !== updatedReport.id);
      state.allReports.reports = state.allReports.reports.filter(report => report.id !== updatedReport.id);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(FetchBackendReports.pending, (state, action) => {
      const tabType = action.meta.arg.status
      const reportState = getReportState(state, tabType);
      reportState.loading = true;
    })
    builder.addCase(FetchBackendReports.fulfilled, (state, action) => {
      const tabType = action.payload.tabType
      const data = { ...action.payload?.data }
      const totalPages = Math.ceil(data.length / pageSize)
      
      const reportState = getReportState(state, tabType);
      // console.log("reportState:", reportState)
      reportState.reports = [...data?.data];
      // console.log("reportState.reports:", reportState.reports)
      reportState.totalCount = data?.length;
      // console.log("reportState.totalCount:", reportState.totalCount)
      reportState.totalPages = totalPages;
      reportState.loading = false;
    })
    builder.addCase(FetchBackendReports.rejected, (state, action) => {
      const tabType = action.payload.tabType
      const reportState = getReportState(state, tabType);
      reportState.loading = false;
    })
    builder.addCase(FetchReport.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(FetchReport.fulfilled, (state, action) => {
      const { report } = action.payload;  
      if (report) {
        state.selectedReport = { ...report }
        // Following are being set for newly processed reports that might have to be reflected on list page (i.e. on retry):
        findAndReplaceReport(state.allReports.reports, report)
        findAndReplaceReport(state.processingReports.reports, report)
      }
      state.loading = false
    })
    builder.addCase(FetchReport.rejected, (state, action) => {
      state.loading = false
    })
    builder.addCase(UpdateReportStatus.pending, (state, action) => {
      state.loading = false
    })
    builder.addCase(UpdateReportStatus.fulfilled, (state, action) => {
      if (action.payload) {
      const { status, oldStatus, id } = action.payload;
        state.selectedReport = { ...state.selectedReport, status };
        findAndReplaceReport(state.allReports.reports, state.selectedReport);

        const oldReportState = getReportState(state, oldStatus);
        oldReportState.reports = oldReportState.reports.filter(report => report.id !== id);
        oldReportState.totalCount -= 1;

        const reportState = getReportState(state, status);
        reportState.reports.unshift(state.selectedReport);
        reportState.totalCount += 1;
      }
      state.loading = false
      state.updating = false

    })
    builder.addCase(UpdateReportStatus.rejected, (state, action) => {
      state.loading = false
      state.updating = false
    })
    builder.addCase(FetchReportTemplates.pending, (state, action) => {
      state.loading = false
    })
    builder.addCase(FetchReportTemplates.fulfilled, (state, action) => {
      if (action.payload) {
        state.reportTemplates = action.payload?.templates
      }
      state.loading = false
    })
    builder.addCase(FetchReportTemplates.rejected, (state, action) => {
      state.loading = false
    })
  }
})

export const { setSelectedReport, UpdateReport, removeReport, setLatestPayload, setFileUploading, setIsDialogOpen, setReportUpdate } = reportSlice.actions

export const selectAllTemplates = (state) => state.reportsSlice?.reportTemplates
export const selectFileUploading = (state) => state.reportsSlice?.fileUploading
export const selectIsDialogOpen = (state) => state.reportsSlice?.isDialogOpen
export const selectAllReports = (state) => state.reportsSlice?.allReports
export const seletecReportLoader = (state) => state.reportsSlice?.loading
export const selectProcessingReports = (state) => state.reportsSlice?.processingReports
export const selectInProgressReports = (state) => state.reportsSlice?.inProgressReports
export const selecteCompletedReports = (state) => state.reportsSlice?.completedReports
export const selecteGeneratedReports = (state) => state.reportsSlice?.generatedReports
export const selectUrgentReports = (state) => state.reportsSlice?.urgentReports
export const selectAllReportsLoading = (state) => state.reportsSlice?.allReports?.loading
export const selectProcessingReportsLoading = (state) => state.reportsSlice?.processingReports?.loading
export const selectInProgressReportsLoading = (state) => state.reportsSlice?.inProgressReports?.loading
export const selectGeneratedReportsLoading = (state) => state.reportsSlice?.generatedReports?.loading
export const selecteCompletedReportsLoading = (state) => state.reportsSlice?.completedReports?.loading
export const selectUrgentReportsLoading = (state) => state.reportsSlice?.urgentReports?.loading
export default reportSlice.reducer