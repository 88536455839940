import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardFooter,
} from "@/components/shadcn-ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "@/components/shadcn-ui/table";
import { 
  Popover, 
  PopoverContent, 
  PopoverTrigger 
} from "@/components/shadcn-ui/popover";
import { GradientBorder } from "@/components/ui/GradientBorder";
import { Trash2, UserRoundX, ChevronDown, EyeOffIcon, EyeIcon, UserPlus, UsersIcon } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { removeMember, selectMembers, updateMember, addMember as addMemberToSlice } from "@/redux/ProfileSlice";
import { selectUserData } from "@/redux/UserSlice";
import { addMember, deleteUser, updateUserRole } from "@/utils/HelperFunctions";
import { Button } from "../shadcn-ui/button";
import { toast } from "react-toastify";
import { ProfileWrapper } from "../ui/CardWrapper";
import { capitalizeFirstLetter } from "@/lib/utils";
import Loader from "../Loader";
import { Label } from "@radix-ui/react-dropdown-menu";
import { Input } from "../shadcn-ui/input";
import ReactPasswordChecklist from "react-password-checklist";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../shadcn-ui/select";

const getAvailableRoles = (member) => {
  if (member.is_admin) {
    return ["assistant", "doctor"];
  }
  if (member.role === "assistant") {
    return ["admin", "doctor"];
  }
  if (member.role === "doctor") {
    return ["admin", "assistant"];
  }
  return [];
};

const AddMemberButton = ({showNewUserForm, setShowNewUserForm}) => {
  return (
    <Button
      onClick={() => {setShowNewUserForm(!showNewUserForm)}}
      className="font-SuisseIntlLight text-sm w-full sm:w-auto"
      >
        {!!showNewUserForm ?
        <div className="flex gap-1 items-center">
          <UsersIcon size={18}/>
          <span>All Members</span> 
        </div>
        :
        <div className="flex gap-1 items-center">
          <UserPlus size={18}/>
          <span>Add Member</span> 
        </div>
        }
    </Button>
  )
}

const Members = () => {
    const members = useSelector(selectMembers) || [];
    const currentUser = useSelector(selectUserData);
    const [loadingUserId, setLoadingUserId] = useState(null); // Track loading state for specific user
    const [deletingUsers, setDeletingUsers] = useState(new Set());
    const [showNewUserForm, setShowNewUserForm] = useState(false)

    const initialFormData = {
      name: "",
      role: 'assistant',
      email: "",
      password: "",
      inviteId: currentUser.organisation_id,
    }

    // states for create user form
    const [formData, setFormData] = useState(initialFormData);
    // const [activeField, setActiveField] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isPassValid, setisPassValid] = useState(false)
    const [isFocused, setIsFocused] = useState(false);

    const validateEmail = (email) => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    };

    const disabled = loading || !isPassValid || !formData.name || !formData.email || !validateEmail(formData.email);

    const handleChange = (e) => {
      const { name, value } = e.target;
  
      setFormData({
        ...formData,
        [name]: value,
      });
    };
    // end states for create user form

    const sortedMembers = [...members].sort((a, b) => {
      if (a.id === currentUser.id) return -1;
      if (b.id === currentUser.id) return 1;
      return b.is_admin - a.is_admin;
    });
    
    const dispatch = useDispatch();

    const handleUpdateUserRole = async (id, role) => {
      setLoadingUserId(id);
      const updatedUser = await updateUserRole({ userId:id, role });
      if (updatedUser) {
        dispatch(updateMember(updatedUser));
        toast.success("Member role updated successfully");
      }
      setLoadingUserId(null);
    };

    const handleDeleteUser = async (id) => {
      setDeletingUsers((prev) => new Set([...prev, id]));
      const result = await deleteUser({ id });
      if (result) {
        dispatch(removeMember(id));
        toast.success("Member removed from organisation");
      }
      setDeletingUsers((prev) => {
        const newSet = new Set(prev);
        newSet.delete(id);
        return newSet;
      });
    };

    const handleAddMember = async () => {
      try {
        setLoading(true);
        const response = await addMember(formData);
        if (response) {
          const newMember = {
            id: response.userId,
            is_admin: response.user.is_admin,
            name: response.user.name,
            role: response.user.role,
          }
          setShowNewUserForm(false)
          setFormData(initialFormData)
          dispatch(addMemberToSlice(newMember))
          toast.success("Member added successfuly.")
        }
      } catch (error) {
        console.error("Error creating member:", error.message);
        toast.error("Failed to add member")
      } finally {
        setLoading(false);
      }
    }

    const updateShowUserFormState = (state) => {
      setShowNewUserForm(state)
    }

    return (
        <ProfileWrapper
          title='Members'
          description="Manage your organization's members"
          hasButton={true}
          buttonContent={<AddMemberButton showNewUserForm={showNewUserForm} setShowNewUserForm={updateShowUserFormState}/>}
        >
            {!!showNewUserForm ?
              <CardContent className="px-0 p-0">
                <Card className="rounded-2xl p-2">
                  <form className="p-2">
                    <div className="grid w-full items-center gap-4 text-left mt-5">
                      <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="name" className="text-sm font-semibold">Name</Label>
                        <Input
                          required
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          placeholder="Please enter your name"
                          autoComplete="off"
                          className="text-[#868C98] font-normal "
                        />
                      </div>
                      <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="role" className="text-sm font-semibold">Role</Label>
                        <Select
                          id="role"
                          name="role"
                          value={formData.role}
                          onValueChange={(value) => setFormData({...formData, role: value})}
                        >
                          <SelectTrigger className="">
                            <SelectValue placeholder="Select language" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="admin">Admin</SelectItem>
                            <SelectItem value="assistant">Assistant</SelectItem>
                            <SelectItem value="doctor">Doctor</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="email" className="text-sm font-semibold">Email</Label>
                        <Input
                          required
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          type="email"
                          placeholder="Please enter your email"
                          autoComplete="new-email"
                          className="text-[#868C98] font-normal "
                        />
                      </div>
                      <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="password" className="text-sm font-semibold">Password</Label>
                        <div className='relative'>
                          <div className="relative">
                            <Input
                              required
                              id="password"
                              name="password"
                              value={formData.password}
                              onChange={handleChange}
                              onFocus={() => setIsFocused(true)}
                              onBlur={() => setIsFocused(false)}
                              placeholder="Please enter your password"
                              type={showPassword ? 'text' : 'password'}
                              autoComplete="new-password"
                              className="text-[#868C98] font-normal "
                            />
                            <button
                              type="button"
                              onClick={() => setShowPassword(!showPassword)}
                              className="absolute inset-y-0 right-3 flex items-center focus:outline-none"
                            >
                              {showPassword ? <EyeOffIcon size={15} strokeWidth={2.5}/> : <EyeIcon size={15} strokeWidth={2.5}/>}
                            </button>
                          </div>
                          {isFocused && (
                            <Card
                              className="absolute top-full mt-[6px] w-full py-2 px-3 rounded-xl z-50"
                            >
                              <ReactPasswordChecklist
                                className="text-sm font-normal"
                                rules={["minLength", "specialChar", "number", "capital"]}
                                minLength={8}
                                value={formData.password}
                                iconSize={11}
                                invalidColor="#71717a"
                                invalidTextColor="#71717a"
                                onChange={(isValid) => {setisPassValid(isValid)}}
                              />
                            </Card>
                          )}
                        </div>
                      </div>
                    </div>
                    <CardFooter className="flex justify-center w-full flex-col p-0 mt-5">
                      <Button variant="default" className="w-full " onClick={(e) => {
                        e.preventDefault()
                        handleAddMember()
                      }} disabled={disabled}>
                        Create Member
                      </Button>
                    </CardFooter>
                  </form>
                </Card>
              </CardContent>
              :
              <CardContent className="px-0 p-0">
                {sortedMembers.length > 0 ? (
                  <Card className="rounded-2xl">
                    <CardContent className="px-0 p-0">
                      <Table>
                        <TableHeader>
                          <TableRow className="hover:bg-transparent">
                            <TableCell className="font-semibold text-[#71717A] pr-0 w-[7%]">
                              ID
                            </TableCell>
                            <TableCell className="font-semibold text-[#71717A]">
                              Name
                            </TableCell>
                            <TableCell className="font-semibold text-[#71717A]">
                              Role
                            </TableCell>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {sortedMembers.map((member, index) => (
                            <TableRow key={member.id}>
                              <TableCell className="text-[#71717A]">
                                {index + 1}
                              </TableCell>
                              <TableCell>{member.name}</TableCell>
                              <TableCell>
                                {(currentUser.is_admin && member.id !== currentUser.id) ? (
                                  <Popover open={loadingUserId === member.id} onOpenChange={(open) => setLoadingUserId(open ? member.id : null)}>
                                    <PopoverTrigger asChild>
                                      <div className="flex items-center gap-1 text-sm cursor-pointer">
                                        {member.is_admin ? "Admin" : capitalizeFirstLetter(member.role)}
                                        <ChevronDown size={16} />
                                      </div>
                                    </PopoverTrigger>
                                    <PopoverContent
                                      align="start"
                                      className="w-fit p-1 text-sm flex flex-col justify-center font-SuisseIntlLight"
                                      disabled={loadingUserId === member.id}
                                    >
                                      {getAvailableRoles(member).map((role) => (
                                        <CardContent
                                          key={role}
                                          className="px-4 py-2 cursor-pointer hover:bg-slate-100 font-SuisseIntlLight hover:text-slate-900"
                                          onClick={() => handleUpdateUserRole(member.id, role)}
                                        >
                                          {capitalizeFirstLetter(role)}
                                        </CardContent>
                                      ))}
                                    </PopoverContent>
                                  </Popover>
                                ) : (
                                  <span>{member.is_admin ? "Admin" : "Member"}</span>
                                )}
                                </TableCell>
                                {(currentUser.is_admin && member.id !== currentUser.id) && (
                                  <TableCell className="align-end pr-0">
                                        {deletingUsers.has(member.id) ? 
                                            <Loader size="18px" className="text-[#71717a]" />
                                          :
                                            <Trash2
                                              className="text-[#71717a] text-sm cursor-pointer"
                                              width={18}
                                              height={18}
                                              onClick={() => handleDeleteUser(member.id)}
                                            />
                                          }
                                  </TableCell>
                                )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>
                ) : (
                  <Card className="rounded-2xl">
                    <CardContent className="p-3 h-96 flex justify-center items-center flex-col">
                      <GradientBorder>
                        <div className="shadow-sm bg-white rounded-full px-4 py-4">
                          <UserRoundX size={26} className="text-gray-600"/>
                        </div>
                      </GradientBorder>
                      <h1 className="font-semibold font-SuisseIntlLight mt-[16px] md:text-[24px] text-[20px] text-center">
                        You don't have any members
                      </h1>
                      <p className="mt-[4px] text-[#505050] my-3 text-[14px] font-SuisseIntlLight font-normal">
                        Add members to your Organisation
                      </p>
                      <AddMemberButton 
                        showNewUserForm={showNewUserForm}  
                        setShowNewUserForm={updateShowUserFormState}
                      />
                    </CardContent>
                  </Card>
                )}
              </CardContent>
            }
        </ProfileWrapper>
    );
};

export default Members;