import React, { useEffect, useState } from 'react'
import { Card, CardContent, CardFooter } from './shadcn-ui/card'
import { Label } from './shadcn-ui/label'
import { Input } from './shadcn-ui/input'
import { Button } from './shadcn-ui/button'
import { Slider } from './shadcn-ui/slider'
import { Link } from 'react-router-dom'
import { createTransaction } from '@/utils/HelperFunctions'
import { useDispatch, useSelector } from 'react-redux'
import { selectPlans, setActiveTab, setNewTransaction } from '@/redux/ProfileSlice'
import { toast } from 'react-toastify'

const TokenTab = ({setIsOpen}) => {
  const dispatch = useDispatch();
  const plans = useSelector(selectPlans);
  
  const [pricePerWord, setPricePerWord] = useState(plans?.[0]?.price_per_word);
  const [amount, setAmount] = useState([1000]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (plans && plans.length > 0) {
      const matchedPlan = plans
        .slice()
        .sort((a, b) => b.minimum_amount - a.minimum_amount) 
        .find(plan => amount[0] >= plan.minimum_amount); 
      setPricePerWord(matchedPlan ? matchedPlan.price_per_word : plans[0]?.price_per_word);
    }
  }, [amount, plans]);

  const handleSubmit =  async (type) => {
    if(type === 'invoice'){
      if(!amount[0]){
        toast.error('Please select a token amount.');
        return;
      }
      setLoading(true);
      const transaction = await createTransaction({ type, amount: amount[0] });
      dispatch(setNewTransaction(transaction));
      toast.success('Request sent successfully');
    }
    setLoading(false);
    setIsOpen(false);
  }

  return (
      <Card className='border-none shadow-none py-0'>
        <CardContent className="p-0 flex flex-col gap-4">
        <div className="flex flex-col space-y-1.5">
            <Label htmlFor="amount" className="font-semibold">Top-Up Amount <span className='text-sm'>(CHF)</span></Label>
            <Input 
              id="amount" 
              className="font-semibold" 
              placeholder="0"
              onChange={(e) => setAmount([Number(e.target.value)] || [0])}
              value={[amount]}
            />
          </div>
          <div>
          <Slider 
            defaultValue={[0]} 
            max={50000} 
            step={100}
            value={[amount]} 
            onValueChange={setAmount}
          />
          </div>
          <div>
            <p className='font-SuisseIntlLight font-medium text-[#505050] text-[12px] mr-2'>You will pay <span className='tracking-wider'>{pricePerWord}</span> CHF per word{" - "}
            <Link className='text-black underline' to={'/settings'} onClick={()=>{dispatch(setActiveTab('plans'), setIsOpen(false))}}>learn more</Link>.
            </p>
          </div>
        </CardContent>
        <CardFooter className="p-0 mt-4">
          <Button 
            disabled={loading}
            className="w-full"
            onClick={loading ? undefined : () => handleSubmit('invoice')}
          >
            Send Request
          </Button>
        </CardFooter>
      </Card>
  )
}

export default TokenTab
