import { fetchAuthSessionWithLogout } from "./authSession";
import { toast } from "react-toastify";

export const createRecord = async ({ userId, toolFieldsData, reportLanguage, uses_custom_template, audioLanguage, comment = '', template, meta_type, toolId, urgent }) => {
  try {
    const tableName = process.env.REACT_APP_TABLE_NAME
    const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT
    const dataToSend = {
      status: "uploading",
      report: "",
      tableName: tableName,
      tool_fields: toolFieldsData,
      audio_language: audioLanguage,
      report_language: reportLanguage,
      user_id: userId,
      uses_custom_template:uses_custom_template,
      template,
      comment,
      tool_id: toolId,
      urgent,
      meta_type: meta_type || "mp3",
    }

    const accessToken = await fetchAuthSessionWithLogout();
    if (!accessToken) {
      return
    }
    let response = await fetch(`${endPoint}/create-record/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken.toString()}`,
      },
      body: JSON.stringify(dataToSend)
    })
    const data = await response.json()

    if (data.statusCode !== 200) {
      throw new Error(`Failed to create record. Status: ${data.statusCode}`);
    }

    return data
  } catch (error) {
    toast.error("Failed to create record. Please try again.");
    console.error("Create Record Error: ", error);
  }
}

export const UpdateReportDetails = async ({ dataToSend }) => {
  try {
    const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT

    const accessToken = await fetchAuthSessionWithLogout();
    if (!accessToken) {
      return
    }
    let response = await fetch(`${endPoint}/update-report/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken.toString()}`,
      },
      body: JSON.stringify(dataToSend)
    })
    const data = await response.json()
    if (data.statusCode === 200) {
      return data?.id
    }
    throw data.error
  } catch (error) {
    toast.error("Failed to update report details. Please try again.");
    console.error("Update Report Error: ", error);
  }
}

export const handleUpdateStatus = async ({ documentId, status }) => {
  try {
    const tableName = process.env.REACT_APP_TABLE_NAME
    const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT
    const dataToSend = {
      status: status,
      tableName: tableName,
      id: documentId
    }
    const accessToken = await fetchAuthSessionWithLogout();
    if (!accessToken) {
      return
    }
    let response = await fetch(`${endPoint}/update-status/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken.toString()}`,
      },
      body: JSON.stringify(dataToSend)
    })
    const data = await response.json()
    if (data.statusCode === 200) {
      return data?.id
    }
    return data
  }
  catch (error) {
    toast.error("Failed to update status. Please try again.");
    console.error("Update Status Error: ", error);
  }
}

export const handleUpdateError = async ({ id, error }) => {
  try{
    const tableName = process.env.REACT_APP_TABLE_NAME
    const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT
    let dataToSend
      dataToSend = {
        error: error,
        status: 'completed',
        tableName: tableName,
        id: id
      }

    const accessToken = await fetchAuthSessionWithLogout();
    if (!accessToken) {
      return
    }
    let response = await fetch(`${endPoint}/update-error/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken.toString()}`,
      },
      body: JSON.stringify(dataToSend)
    })
    response = await response.json()
    if (response.statusCode === 200) {
      return response.message
    }
    return response.message
  } catch (error) {
    toast.error("Failed to update error. Please try again.");
    console.error("Update Error Error: ", error);
  }
}

export const getuser = async ({ user_id }) => {
  try {
    const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT
    const accessToken = await fetchAuthSessionWithLogout();
    if (!accessToken) {
      return
    }
    let response = await fetch(`${endPoint}/get-user/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken.toString()}`,
      },
      body: JSON.stringify({ user_id })
    })
    response = await response.json()
    if (response.statusCode === 200) {
      return response.user
    }
    return null
  } catch (error) {
    console.log("Get user error ", error);
    toast.error("Failed to fetch user details. Please try again.");
    return null
  }
}

export const getReport = async (id, tool_fields) => {
  const accessToken = await fetchAuthSessionWithLogout();
  if (!accessToken) {
    return
  }
  const response = await fetch(`${process.env.REACT_APP_AWS_API_ENDPOINT}/report`, {
    method: 'POST',
    body: JSON.stringify({ id, tool_fields }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken.toString()}`,
    },
  });
  const responseData = await response.json()
  return { response, responseData };
}


export const getSignedUrl = async ({ id, name, type, meta_type}) => {
  try {
    const accessToken = await fetchAuthSessionWithLogout();
    if (!accessToken) {
      return
    }
    const response = await fetch(`${process.env.REACT_APP_AWS_API_ENDPOINT}/get-signed-url`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken.toString()}`,
      },
      body: JSON.stringify({
        name: name,
        type,
        meta_type: meta_type
      })
    })
    if (!response.ok) {
      throw new Error(`Signed url not found Status: ${response.status}`);
    }
    const data = await response.json()
    return data.url;
  } catch (errorInCatch) {
    handleUpdateError({ id: id, error: JSON.stringify(errorInCatch) })
    console.log("Error while updating supabase ", errorInCatch);
    toast.error("Failed to get signed URL. Please try again.");
  }
}

export const uploadToS3 = async ({ id, file, signedUrl }) => {
  try {
    console.log("Signed url is ", signedUrl);
    const response = await fetch(signedUrl, {
      method: 'PUT',
      body: file,
      headers: {  
        'Content-Type': file.type,
      }
    });
    if (response.ok) {
      console.log('File uploaded successfully');
      return { documentId: id, uploadStatus: true }
    } else {
      throw new Error(`Failed to upload file: ${response.statusText}`);
    }
  } catch (errorInCatch) {
    handleUpdateError({ id: id, error: JSON.stringify(errorInCatch) })
    console.log("Error while updating supabase ", errorInCatch);
    toast.error("Failed to upload file. Please try again.");
  }
}

export const updateServiceName = async ({ user_id, service_name }) => {
  try {
    const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT
    const accessToken = await fetchAuthSessionWithLogout();
    if (!accessToken) {
      return
    }
    let response = await fetch(`${endPoint}/update-service/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken.toString()}`,
      },
      body: JSON.stringify({ user_id, service_name })
    })
    response = await response.json()
    if (response.statusCode === 200) {
      return response.user
    }
    return null
  } catch (error) {
    console.log("update service name error ", error);
    toast.error("Failed to update service name. Please try again.");
    return null
  }
} 

export const createUser = async ({ name, email, password, inviteId, orgName }) => {
  try {
    const endPoint = process.env.REACT_APP_AWS_AUTH_API_ENDPOINT

    let response = await fetch(`${endPoint}/create-user/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, email, password, inviteId, orgName })
    })
    response = await response.json()
    if (response.statusCode !== 200) {
      throw response
    }
    return response.userId
  }
  catch (error) {
    console.log("Create user error:", error);
    const message = error.error || error.message;
    switch (message) {
      case 'Invalid organisation Id':
        toast.error("Invalid invite Id");
        break;
      case 'User account already exists':
        toast.error("Email already registered. Please login.");
        break;
      default:
      toast.error("Failed to sign up. Please try again.");
    }
    return null
  }
}

export const addMember = async ({ name, role, email, password, inviteId }) => {
  try {
    const endPoint = process.env.REACT_APP_AWS_AUTH_API_ENDPOINT
    const accessToken = await fetchAuthSessionWithLogout();
    if (!accessToken) {
      return
    }

    let response = await fetch(`${endPoint}/add-new-member/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken.toString()}`,
      },
      body: JSON.stringify({ name, role, email, password, inviteId })
    })
    response = await response.json()
    if (response.statusCode !== 200) {
      throw response
    }
    return response
  }
  catch (error) {
    console.log("Add member error:", error);
    const message = error.error || error.message;
    switch (message) {
      case 'Invalid organisation Id':
        toast.error("Invalid invite Id");
        break;
      case 'User account already exists':
        toast.error("Email already registered.");
        break;
      default:
      toast.error("Failed to add member. Please try again.");
    }
    return null
  }
}

export const deleteUser = async ({ id }) => {
  try {
    const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT
    const accessToken = await fetchAuthSessionWithLogout();
    if (!accessToken) {
      return
    }
    let response = await fetch(`${endPoint}/delete-user/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken.toString()}`,
      },
      body: JSON.stringify({ id })
    })
    response = await response.json()
    if (response.statusCode !== 200) {
      throw response.message
    }
    return true
  } catch (error) {
    console.log("Delete user error ", error);
    toast.error("Failed to remove member. Please try again.");
    return null
  }
}

export const updateUserRole = async (data) => {
  try {
    const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT
    const accessToken = await fetchAuthSessionWithLogout();
    if (!accessToken) {
      return
    }
    let response = await fetch(`${endPoint}/update-user/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken.toString()}`,
      },
      body: JSON.stringify(data)
    })
    response = await response.json()
    if (response.statusCode !== 200) {
      throw response.message
    }
    return response.user
  } catch (error) {
    console.log("Update member role error ", error);
    toast.error("Failed to update member role. Please try again.");
    return null
  }
}

export const createSubstitute = async (data) => {
  try {
    const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT;
    const accessToken = await fetchAuthSessionWithLogout();
    if (!accessToken) {
        return
    }
    let response = await fetch(`${endPoint}/create-substitute/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken.toString()}`,
        },
        body: JSON.stringify(data)
    })
    response = await response.json()
    if (response.statusCode === 201) {
      return response.substitute
    }
    throw response.message
  } catch (error) {
    console.log("Create substitute error", error)
    toast.error("Failed to create substitute. Please try again.");
    return null;
  }
}
export const deleteSubstitute = async (id) => {
  try {
    const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT;
    const accessToken = await fetchAuthSessionWithLogout();
    if (!accessToken) {
        return
    }
    let response = await fetch(`${endPoint}/delete-substitute/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken.toString()}`,
        },
        body: JSON.stringify(id)
    })
    response = await response.json()
    if (response.statusCode === 200) {
      return response.substitute
    }
    throw response.message
  } catch (error) {
    console.log("Delete substitute error", error)
    toast.error("Failed to delete substitute. Please try again.");
    return null;
  }
}

export const createTransaction = async (data) => {
  try {
    const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT;
    const accessToken = await fetchAuthSessionWithLogout();
    if (!accessToken) {
        return
    }
    let response = await fetch(`${endPoint}/create-transaction/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken.toString()}`,
        },
        body: JSON.stringify(data)
    })
    response = await response.json()
    if (response.statusCode === 200) {
      return response.transaction
    }
    throw response.error
  } catch (error) {
    console.log("Create transaction error", error)
    toast.error("Failed to create transaction. Please try again.");
    return null;
  }
}

