import { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
  selectAllReports,
  selectInProgressReports,
  selectProcessingReports,
  selectUrgentReports,
  selecteCompletedReports,
  selecteGeneratedReports
} from '../redux/ReportsSlice';
import { useSelector } from 'react-redux';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const getCompletedCount = (completed) => {
  return completed?.reports?.filter(report => report.report_fetched).length;
};

const StatusDropdown = ({ tabs, selected, handler, mobileView, reports, disable = false, showReportCount = false, isDrawer }) => {
  const [selectedStatus, setSelectedStatus] = useState(selected);
  const reportsCount = {
    "all": useSelector(selectAllReports)?.totalCount,
    "processing": useSelector(selectProcessingReports)?.totalCount,
    "in-progress": useSelector(selectInProgressReports)?.totalCount,
    "generated": useSelector(selecteGeneratedReports)?.totalCount,
    "completed": getCompletedCount(useSelector(selecteCompletedReports)),
    "urgent": useSelector(selectUrgentReports)?.totalCount
  }

  const translateStatus = async (status) => {
    const newLanguage = await window.Weglot.getCurrentLang();
    const words = [{ t: 1, w: status }];
    const languageTo = newLanguage;
    const data = await window.Weglot.translate({ words, languageTo });

    if (data) {
      setSelectedStatus(data[0]);
    } else {
      setSelectedStatus(status);
    }
  };

  useEffect(() => {
    if (window.Weglot) {
      translateStatus(selected);
    } else {
      setSelectedStatus(selected);
    }

    const handleLanguageChange = () => {
      translateStatus(selected);
    };

    window.Weglot.on('languageChanged', handleLanguageChange);

    return () => {
      window.Weglot.off('languageChanged', handleLanguageChange);
    };
  }, [selected]);

  return (
    <Menu as="div" className={`relative inline-block text-left ${mobileView ? 'w-full' : ''}`}>
      <div>
        <Menu.Button disabled={disable} className={`border-[1px] h-10 border-[#E2E4E9] inline-flex px-4 items-center justify-between sm:justify-center gap-x-1.5 rounded-[10px] bg-white py-2 text-sm font-normal text-gray-900 hover:border-[#CED0D5]`}>
          <span className={`hidden sm:flex capitalize ${isDrawer ? "text-black font-medium text-[14px]" : "text-[#525866]"} h-full flex-col justify-center`}>{selectedStatus.replace(/-/g, ' ')}</span>
          <span className={`flex sm:hidden capitalize ${isDrawer ? "text-black font-medium text-[14px]" : "text-[#525866]"} h-full flex-col justify-center`}>{selectedStatus.replace(/-/g, ' ')}</span>
          <ChevronDownIcon className={`-mr-[4px] h-5 w-5 ${ isDrawer ? "text-black":"text-gray-400"}`} aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {tabs.map((tab) => (
              <Menu.Item key={tab.value}>
                {({ active }) => (
                  <div
                    onClick={() => handler(reports ? tab.name : tab?.value, selected)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm cursor-pointer'
                    )}
                  >
                     <span className="inline-block">
                      <span>{tab.name}</span>
                      {showReportCount && ( <> 
                      <span className="mx-1 wg-ignore">&middot;</span>
                      <span className="wg-ignore">{reportsCount[tab.value]}</span>
                      </>)}
                    </span>
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default StatusDropdown;
